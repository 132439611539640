// const baseUrl = 'http://13.49.168.229:8000';
// const baseUrl = 'https://api.shipcrm.atts.in';
const baseUrl = 'http://13.49.168.229:8007';



export const iconUploadEndPoint = baseUrl + '/miscellenious/icon-upload/';

export const loginEndPoint = baseUrl + '/accounts/login/';
export const logoutEndPoint = baseUrl + '/accounts/logout/';
export const changePasswordEndPoint = baseUrl + '/accounts/change-password/';

export const menuPermissionEndPoint = baseUrl + '/settings/menu-permission/';
export const activeUserPermissionEndPoint = baseUrl + '/settings/active-user-menu-permission/';
export const gstVerifyEndPoint = baseUrl + '/settings/gst-verification/';
export const ifscVerifyEndPoint = baseUrl + '/settings/ifsc-verification/';

export const statusTypeListEndPoint = baseUrl + '/settings/status-type-list/';
export const utrTypeListEndPoint = baseUrl + '/settings/utr-type-list/';

export const userRoleListEndPoint = baseUrl + '/settings/user-role-list/';
export const userRoleEndPoint = baseUrl + '/settings/user-role/';
export const userRoleStatusEndPoint = baseUrl + '/settings/change-user-role-status/';

export const menuListEndPoint = baseUrl + '/settings/menu-list/';
export const menuEndPoint = baseUrl + '/settings/menu/';
export const menuStatusEndPoint = baseUrl + '/settings/change-menu-status/';

export const menuGroupListEndPoint = baseUrl + '/settings/menu-group-list/';
export const menuGroupEndPoint = baseUrl + '/settings/menu-group/';
export const menuGroupStatusEndPoint = baseUrl + '/settings/change-menu-group-status/';

export const locationListEndPoint = baseUrl + '/organizations/location-list/';
export const locationEndPoint = baseUrl + '/organizations/location/';
export const locationStatusEndPoint = baseUrl + '/organizations/change-location-status/';




export const stateDropdownEndPoint = baseUrl + '/organizations/state-list/';
export const countryDropdownEndPoint = baseUrl + '/organizations/country-list/';
export const cityDropdownEndPoint = baseUrl + '/organizations/city-list/';




export const branchListEndPoint = baseUrl + '/organizations/branch-list/';
export const branchEndPoint = baseUrl + '/organizations/branch/';
export const branchStatusEndPoint = baseUrl + '/organizations/branch-status/';

export const departmentListEndPoint = baseUrl + '/organizations/department-list/';
export const departmentEndPoint = baseUrl + '/organizations/department/';
export const departmentStatusEndPoint = baseUrl + '/organizations/change-department-status/';

export const designationListEndPoint = baseUrl + '/organizations/designation-list/';
export const designationEndPoint = baseUrl + '/organizations/designation/';
export const designationStatusEndPoint = baseUrl + '/organizations/change-designation-status/';

export const staffListEndPoint = baseUrl + '/organizations/staff-list/';
export const staffEndPoint = baseUrl + '/organizations/staff/';
export const staffStatusEndPoint = baseUrl + '/organizations/change-staff-status/';
export const staffGetbyMobileNumberEndPoint = baseUrl + '/organizations/search-user-by-mobile/';

export const userListEndPoint = baseUrl + '/organizations/user-list/';
export const userEndPoint = baseUrl + '/organizations/user/';
export const userStatusEndPoint = baseUrl + '/organizations/change-user-status/';
export const userListByRoleEndPoint = baseUrl + '/organizations/user-list-by-role/';


export const LeadTypeDataEndPoint = baseUrl + '/settings/lead-type-list/';
export const excelUploadEndPoint = baseUrl + '/lead/match-columns/';
export const leadSourceDropdownEndPoint = baseUrl + '/settings/digital-source-type/';

export const importLeadDataEndPoint = baseUrl + '/lead/import-lead-data/';

export const allLeadsTableDataEndPoint = baseUrl + '/lead/lead-list/';
export const allLeadsDeleteEndPoint = baseUrl + '/lead/deletelead/';



export const getDatabyIdEndPoint = baseUrl + '/lead/incoming/';
export const getALLDatabyIdEndPoint = baseUrl + '/lead/incoming-list/';


export const allPatientTableDataEndPoint = baseUrl + '/patient/patient-list/';
export const patientEndPoint = baseUrl + '/patient/patient/';



export const feesTypeListEndPoint = baseUrl + '/settings/fees-type-by-type/';
export const sorceTypeListEndPoint = baseUrl + '/settings/source-type-list/';
export const sorceTypeotherListEndPoint = baseUrl + '/settings/source-othertype-list/';
export const genderTypeListEndPoint = baseUrl + '/settings/gender-type-list/';

export const patientTypeListEndPoint = baseUrl + '/settings/patienttype/';


//Dashboard
export const dashboardStatusEndPoint = baseUrl + '/dashboard/display-count/';

//All procedure
export const allprocedureEndpoint = baseUrl + '/patient/patient-procedure-list/';
export const procedureCreateEndpoint = baseUrl + '/patient/patient-procedure/';
export const ReviewListEndPoint = baseUrl + '/patient/patient-details/';
export const entryDropdownListEndPoint = baseUrl + '/settings/entry-type-list/';
export const procedureDropdownListEndPoint = baseUrl + '/settings/procedure-type-list/';
export const entryDropdownprocedureListEndPoint = baseUrl + '/settings/entry-based-type/2/';
export const dischargeDetailsListEndPoint = baseUrl + '/patient/patient-discharge/';
export const typewayListEndPoint = baseUrl + '/settings/procedure-type-way/';

// paientList
export const PatientreviewListEndPoint = baseUrl + '/patient/patientreview-list/';
export const ReviewtypeEndPoint = baseUrl + '/settings/review-type-list/';
export const ReviewtypeDropDownEndPoint = baseUrl + '/settings/withoutreviewstatus-list/';
export const creatPatientReviewEndPoint = baseUrl + '/patient/patientreview/';


export const ReviewStatusListEndPoint = baseUrl + '/settings/reviewstatus-list/';
export const NextReviewListEndPoint = baseUrl + '/patient/patientnextreview-list/';
export const NextReviewStatusChangeEndPoint = baseUrl + '/patient/change-review-status/';
export const NextReviewStatusDataGetEndPoint = baseUrl + '/patient/patientreview/';


// incomming leades
export const allIncommingTableDataEndPoint = baseUrl + '/lead/incoming-list/';
export const incommingEndPoint = baseUrl + '/lead/incoming/';

export const missedLeadsEndPoint = baseUrl + '/lead/missed-appointment-list/';
export const branchTranferListEndPoint = baseUrl + '/lead/transferlead-list/';

// Entry type
export const entryEndPoint = baseUrl + '/settings/display-type-list/';
 
// Fee Type
export const feeEndPoint = baseUrl + '/settings/fees-type-by-type/';


export const userEmailSearchEndPoint = baseUrl + '/organizations/search-user-by-email/';

export const leadsPopUpStatusEndPoint = baseUrl + '/settings/status-type-popuplist/';
export const leadsPopUpStatusbasedEndPoint = baseUrl + '/settings/status-based-displaylist/';

export const importBranchListEndPoint = baseUrl + '/organizations/import-branch-list/';

export const patientMobilenumberEndpoint = baseUrl + '/patient/search-mobile/';
export const patientPhonenumberEndpoint = baseUrl + '/patient/search-phone/';
export const getUpdateleadEndPoint = baseUrl + '/lead/updatelead/';

// canceltreatment
export const cancelTreatmentEndPoint = baseUrl + '/patient/treatmentcancel/';
export const cancelTreatmentTableListEndPoint = baseUrl + '/patient/treatmentcancel-list/';


export const branchtransferstatusdropdownEndPoint = baseUrl + '/settings/status-based-tansfer/';

export const sourcedisplaytypedropdownEndPoint = baseUrl + '/settings/source-display-type/';



export const s3ImageUploadEndPoint = baseUrl + '/miscellaneous/s3-upload/';



// sollu integration api
export const leadAgentListEndPoint = baseUrl + '/lead/agentphone-list/';
export const leadCallEndPoint = baseUrl + '/lead/call/';
export const leadCallLogEndPoint = baseUrl + '/lead/call-logtable/';
export const leadDischargeEndPoint = baseUrl + '/patient/discharge-list/';

export const missedCallesEndPoint = baseUrl + '/lead/incoming-call-list/';




export const leadViewEndPoint = baseUrl + '/lead/leadshistory-list/';
export const reviewViewEndPoint = baseUrl + '/patient/review-history/';

// dropout import
export const dropOutDropdownEndPoint = baseUrl + '/settings/dropout-type/';
export const statusDropdownEndPoint = baseUrl + '/settings/dropout-status/';

export const excelDropoutUploadEndPoint = baseUrl + '/dropoutpatient/match-columns/';
export const importDropoutDataEndPoint = baseUrl + '/dropoutpatient/import-dropout-data/';
export const DropoutTableListDataEndPoint = baseUrl + '/dropoutpatient/dropout-patient-list/';
export const DropoutViewDataEndPoint = baseUrl + '/dropoutpatient/dropout-patienthistory-list/';
export const DropoutStatusUpdateEndPoint = baseUrl + '/dropoutpatient/updatedropoutpatient/';
export const DropoutStatusGetDataEndPoint = baseUrl + '/dropoutpatient/dropoutpatient/';
export const DropoutListDeleteDataEndPoint = baseUrl + '/dropoutpatient/deletedropoutpatient/';

// cancerleads 
export const CancerLeadListDataEndPoint = baseUrl + '/cancercenter/cancerlead-list/';


// multi delete
export const newLeadsMUltiDeleteListDataEndPoint = baseUrl + '/lead/multipledeletelead/';


// Notification
export const notificationListDataEndPoint = baseUrl + '/patient/notification-message/';
export const notificationStatusListDataEndPoint = baseUrl + '/patient/notification-status/';
export const notificationTableListDataEndPoint = baseUrl + '/patient/notification-tablelist/';
export const notificationStatusDropdownEndPoint = baseUrl + '/settings/notification-status-list/';
export const notificationChangeApproveEndPoint = baseUrl + '/patient/changeapprovesourcetype/';

