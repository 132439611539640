import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  getAxios,
  postAxios,
  postAxiosWithToken,
} from "../../../../service/apiservice";
import {
  branchListEndPoint,
  incommingEndPoint,
  LeadTypeDataEndPoint,
  patientMobilenumberEndpoint,
  sorceTypeListEndPoint,
  sorceTypeotherListEndPoint,
} from "../../../../service/api";
import toast from "react-hot-toast";
import { phoneRegExp } from "../../../../constant";
import { Navigate, useNavigate } from "react-router-dom";

const Addincomingform = ({ onClose, getAllLeadsListData }) => {
  const [formMode, setFormMode] = useState("create");
  const [branchDropdown, setBranchDropdown] = useState([]);
  const [sorceDropdown, setSorceDropdown] = useState([]);
  const [selectedOptionLabel, setSelectedOptionLabel] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [alterNumber, setAlterNumber] = useState("");
  const [usedNumber, setUsedNumber] = useState(null);
  const [hasPatientDetails, setHasPatientDetails] = useState(false);
  const [currentPatient, setCurrentPatient] = useState({});
  console.log("currentPatient", currentPatient);
  const [errors, setErrors] = useState(null);
  const [sourceDisplaytype, setSourceDisplaytype] = useState(2);
  const [response, setResponse] = useState({});
  const [leadtypeDropdown, setLeadTypeDropdown] = useState([]);
  const [dataCame, setDataCame] = useState(false);

  const [selected, setSelected] = useState(null);
  const [selected1, setSelected1] = useState(null);
  const [leadTypesDrop, setLeadTypesDrop] = useState("");
  const [sroceTypeDrop, setSroceTypeDrop] = useState("");
  const [branchDrop, setBranchDrop] = useState("");

  const [sourceDropdown, setSourceDropdown] = useState([]);

  const mobileNumbers = {
    mobile: mobileNumber,
    alternate_no: alterNumber,
  };

  useEffect(() => {
    getBranchDropdownData();
    getSorceTypeDropdownData();
    getLeadDropdownData();
    getSourceDropdownData();
  }, []);

  // State to hold form data
  const [formData, setFormData] = useState({
    name: "",
    spousename: "",
    appointment_date: "",
    primary_contact_number: "",
    spouse_contact_no: "",
    leadtype: "",
    additional_contact_number: "",
    location: "",
    camp_name: "",
    email: "",
    branch: "",
    source_type: "",
    married_since: "",
    remarks: "",
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name == "leadtype") {
      console.log("jijij", value);
      if (value === 1) {
        setLeadTypesDrop("Daily Walk In");
      } else if (value === 2) {
        setLeadTypesDrop("Camp");
      } else if (value === 3) {
        setLeadTypesDrop("TeleCaller");
      } else {
        setLeadTypesDrop("Website");
      }
    }

    if (name == "source_type") {
      if (value == 1) {
        setSroceTypeDrop("Patient Referral");
      } else if (value == 2) {
        setSroceTypeDrop("Doctor Referral");
      } else if (value == 4) {
        setSroceTypeDrop("Staff Referral");
      } else if (value == 5) {
        setSroceTypeDrop("Relative/Friends Referral");
      } else if (value == 6) {
        setSroceTypeDrop("Facebook");
      } else if (value == 7) {
        setSroceTypeDrop("Instagram");
      } else if (value == 8) {
        setSroceTypeDrop("Flex | Hoardings");
      } else if (value == 9) {
        setSroceTypeDrop("Tv");
      } else if (value == 10) {
        setSroceTypeDrop("Radio");
      } else if (value == 11) {
        setSroceTypeDrop("Notice / Pamphlet");
      } else if (value == 12) {
        setSroceTypeDrop("Newspaper");
      } else if (value == 13) {
        setSroceTypeDrop("Outside Camp Doctor Referral");
      } else if (value == 14) {
        setSroceTypeDrop("Outside Camp Staff Referral");
      } else if (value == 15) {
        setSroceTypeDrop("WhatsApp");
      } else if (value == 17) {
        setSroceTypeDrop("Youtube");
      } else if (value == 18) {
        setSroceTypeDrop("Incoming calls");
      } else if (value == 19) {
        setSroceTypeDrop("Camp Other");
      } else if (value == 21) {
        setSroceTypeDrop("Website");
      } 
      else if (value == 24) {
        setSroceTypeDrop("Other");
      }
      else if (value == 25) {
        setSroceTypeDrop("Camp Digital");
      }
      else if (value == 27) {
        setSroceTypeDrop("Google");
      }
      else if (value == 28) {
        setSroceTypeDrop("Audience Network");
      }
    }


    if(name == "branch"){
      if(value == 1 ){
        setBranchDrop("erode")
      } else if(value == 2){
        setBranchDrop("coimbatore")
      }
      else if(value == 3){
        setBranchDrop("salem")
      }
      else if(value == 4){
        setBranchDrop("chennai")
      }
      else if(value == 5){
        setBranchDrop("madurai")
      }
      else if(value == 6){
        setBranchDrop("trichy")
      }
      else if(value == 7){
        setBranchDrop("colombo")
      }
      else if(value == 8){
        setBranchDrop("thanjavur")
      }
      else if(value == 9){
        setBranchDrop("bengaluru")
      }
      else if(value == 10){
        setBranchDrop("hyderabad")
      }
      else if(value == 11){
        setBranchDrop("theni")
      }
      else if(value == 12){
        setBranchDrop("vellore")
      }
      else if(value == 13){
        setBranchDrop("tiruppur")
      }
      else if(value == 14){
        setBranchDrop("puducherry")
      }
      else if(value == 15){
        setBranchDrop("palakkad")
      }
      else if(value == 16){
        setBranchDrop("krishnagiri")
      }
      else if(value == 17){
        setBranchDrop("attapur")
      }
      else if(value == 18){
        setBranchDrop("tiruchengode")
      }
      else if(value == 19){
        setBranchDrop("namakkal")
      }
      else if(value == 20){
        setBranchDrop("karur")
      }
      else if(value == 21){
        setBranchDrop("ambattur")
      }
      else if(value == 22){
        setBranchDrop("vijayawada")
      }
      else if(value == 23){
        setBranchDrop("karaikudi")
      }
      else if(value == 24){
        setBranchDrop("dharmapuri")
      }
      else if(value == 25){
        setBranchDrop("ramanathapuram")
      }
      else if(value == 26){
        setBranchDrop("tirunelveli")
      }
      else if(value == 27){
        setBranchDrop("dindigul")
      }
      else if(value == 28){
        setBranchDrop("hosur")
      }
      else if(value == 29){
        setBranchDrop("Unallocate")
      }
      else if(value == 31){
        setBranchDrop("Purasaivakkam")
      }
      else if(value == 32){
        setBranchDrop("tiruvannamalai")
      }
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getSourceDropdownData = async () => {
    var response = await getAxios({
      url: sorceTypeListEndPoint,
    });

    if (response !== null) {
      setSourceDropdown(response.data.list);
    }
  };

  // Submit the form data to Google Sheets via the Web App
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure the fields are not empty
    if (
      !formData.name ||
      !formData.spousename ||
      !selected ||
      !selected1 ||
      !formData.appointment_date ||
      !formData.primary_contact_number ||
      !leadTypesDrop ||
      !sroceTypeDrop ||
      !formData.additional_contact_number ||
      !formData.location ||
      !branchDrop ||
      !formData.source_type ||
      !formData.married_since
    ) {
      alert("All fields are required.");
      return;
    }

    // Prepare the data to be sent
    const dataToSend = {
      name: formData.name,
      spousename: formData.spousename,
      appointment_date: formData.appointment_date,
      primary_contact_number: formData.primary_contact_number,
      spouse_contact_no: formData.spouse_contact_no,
      leadtype: leadTypesDrop,
      additional_contact_number: formData.additional_contact_number,
      location: formData.location,
      camp_name: formData.camp_name,
      email: formData.email,
      branch: branchDrop,
      source_type: sroceTypeDrop,
      married_since: formData.married_since,
      remarks: formData.remarks,
      Treatment: selected,
      firstbaby: selected1,
    };

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbw76ZQgmMpfjv3hKqbHY7p42s9OTcxvj6m7oofzAEAGEspJZaJ_hK9OQwn328RUP502ZQ/exec", // Your Google Apps Script URL
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          body: new URLSearchParams(dataToSend),
        }
      );

      const result = await response.json();
      console.log(result); // Log the response to the console
      alert("Form submitted successfully!");

      // Reset form data after successful submission
      setFormData({
        name: "",
        spousename: "",
        appointment_date: "",
        primary_contact_number: "",
        spouse_contact_no: "",
        leadtype: "",
        additional_contact_number: "",
        location: "",
        camp_name: "",
        email: "",
        branch: "",
        source_type: "",
        married_since: "",
        remarks: "",
      });
      setSelected1(null);
      setSelected(null);
      setLeadTypesDrop("");
      setSroceTypeDrop("");
      setBranchDrop("");
    } catch (error) {
      console.error("Error:", error);
      alert("Error submitting the form.");
    }
  };

  // useEffect(() => {
  //   handleSearch()
  // }, [mobileNumber])

  const getLeadDropdownData = async () => {
    var response = await getAxios({
      url: LeadTypeDataEndPoint,
    });

    if (response !== null) {
      setLeadTypeDropdown(response.data.list);
    }
  };

  const getBranchDropdownData = async () => {
    var response = await getAxios({
      url: branchListEndPoint,
    });

    if (response !== null) {
      setBranchDropdown(response.data.list);
    }
  };
  const getSorceTypeDropdownData = async () => {
    var response = await getAxios({
      url: sorceTypeotherListEndPoint,
    });

    if (response !== null) {
      setSorceDropdown(response.data.list);
    }
  };

  const handleSelect = (value) => {
    setSelected(value);
  };
  const handleSelect1 = (value) => {
    setSelected1(value);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="w-full h-full pb-[30px] pt-[20px] px-[30px]">
          <div className="flex gap-[10px] mt-4">
            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">
                Name <span className="text-red-500">*</span>
              </p>

              <input
                type="text"
                name="name"
                placeholder="Name"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={formData.name}
                onChange={handleInputChange}
              />
              <p style={{ color: "red" }}>{errors?.name}</p>
            </div>

            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">
                Spouse Name <span className="text-red-500">*</span>{" "}
              </p>
              <input
                type="text"
                name="spousename"
                id="spousename"
                placeholder="Spouse Name"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={formData.spousename}
                onChange={handleInputChange}
              />
              {/* <p style={{ color: 'red' }}>{errors?.email}</p> */}
            </div>
            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">
                Appointment Date <span className="text-red-500">*</span>
              </p>
              <input
                type="date"
                name="appointment_date"
                id="appointment_date"
                placeholder="E-mail"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={formData.appointment}
                onChange={handleInputChange}
              />
              <p style={{ color: "red" }}>{errors?.appointment}</p>
            </div>
          </div>
          <div className="flex gap-[10px] mt-4">
            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">
                Primary Contact Number <span className="text-red-500">*</span>
              </p>

              <input
                type="text"
                name="primary_contact_number"
                placeholder="Primary Contact Number"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={formData.primary_contact_number}
                onChange={handleInputChange}
              />
              <p style={{ color: "red" }}>{errors?.primary_contact_number}</p>
            </div>

            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">Spouse Contact No </p>
              <input
                type="text"
                name="spouse_contact_no"
                id="spouse_contact_no"
                placeholder="Spouse Contact No "
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={formData.spouse_contact}
                onChange={handleInputChange}
              />
              <p style={{ color: "red" }}>{errors?.spouse_contact}</p>
            </div>
            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">
                Lead Type <span className="text-red-500">*</span>
              </p>

              <div className=" ">
                <select
                  placeholder="Leads Type"
                  className="sm:mb-[5px] h-[40px] w-full capitalize bg-white border border-gray-300 rounded-lg p-[11px] text-[15px] outline-none"
                  name="leadtype"
                  onChange={handleInputChange}
                  value={formData.leadtype}
                >
                  <option value={""}>--select LeadsType--</option>
                  {leadtypeDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.leadtype_name}
                    </option>
                  ))}
                </select>
              </div>
              <p style={{ color: "red" }}>{errors?.leadtype}</p>
            </div>
          </div>

          <div className="flex gap-[10px] mt-4">
            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">
                Additional Contact Number{" "}
                <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                name="additional_contact_number"
                id="additional_contact_number"
                placeholder="Additional Contact Number"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={formData.mobile}
                onChange={handleInputChange}
              />
              <p style={{ color: "red" }}>
                {errors?.additional_contact_number}
              </p>
            </div>

            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">
                Location <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                name="location"
                id="location"
                placeholder="Location"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={formData.additional_mobile}
                onChange={handleInputChange}
              />
            </div>
            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">Camp Name </p>
              <input
                type="text"
                name="camp_name"
                id="camp_name"
                placeholder="Camp Name"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={formData.camp_name}
                onChange={handleInputChange}
              />
              <p style={{ color: "red" }}>{errors?.camp_name}</p>
            </div>
          </div>

          <div className="flex gap-[10px] mt-4">
            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">E-mail </p>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="email"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={formData.email}
                onChange={handleInputChange}
              />
              <p style={{ color: "red" }}>{errors?.email}</p>
            </div>
            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">
                Branch <span className="text-red-500">*</span>
              </p>
              {/* <input
                type="text"
                name="branch"
                id="branch"
                placeholder="branch"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={formData.branch}
                onChange={handleInputChange}
              /> */}

              <select
                placeholder="Branch"
                className="sm:mb-[5px] h-[40px] w-full capitalize bg-white border border-gray-300 rounded-lg p-[11px] text-[15px] outline-none"
                name="branch"
                value={formData.branch}
                onChange={handleInputChange}
              >
                <option value={""}>--select branch--</option>
                {branchDropdown.map((option) => (
                  <option
                    key={option.id}
                    value={option.id}
                    className="capitalize"
                  >
                    {option.branch_name}
                  </option>
                ))}
              </select>
              <p style={{ color: "red" }}>{errors?.branch}</p>
            </div>
            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">
                Have You Ever Undergone Treatment Before?
                <span className="text-red-500">*</span>
              </p>

              <div className="flex space-x-4">
                <button
                  type="button"
                  className={`px-6 py-2 text-lg border rounded-md ${
                    selected === "Yes"
                      ? "bg-green-500 text-white border-green-500"
                      : "bg-white text-black border-gray-300 hover:bg-gray-100"
                  }`}
                  onClick={() => handleSelect("Yes")}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className={`px-6 py-2 text-lg border rounded-md ${
                    selected === "No"
                      ? "bg-red-500 text-white border-red-500"
                      : "bg-white text-black border-gray-300 hover:bg-gray-100"
                  }`}
                  onClick={() => handleSelect("No")}
                >
                  No
                </button>
              </div>
            </div>
          </div>

          <div className="flex gap-[10px] mt-4">
            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">
                Married Since <span className="text-red-500">*</span>
              </p>
              <input
                type="text"
                name="married_since"
                id="married_since"
                placeholder="Married Since"
                className="sm:mb-[5px] h-[45px] border border-gray-300 rounded-lg w-full p-[10px] text-[17px] outline-none"
                value={formData.married_since}
                onChange={handleInputChange}
              />
              <p style={{ color: "red" }}>{errors?.married_since}</p>
            </div>

            <div className="w-1/2">
              <p className="text-black text-[15px] font-500 mt-1">
                Source Type <span className="text-red-500">*</span>
              </p>

              <div className="max-[520px]:mt-[5px] max-[575px]:w-full max-[520px]:mb-[50px] max-[575px]:mt-4  ">
                <select
                  placeholder="Source"
                  className="sm:mb-[5px] h-[40px] w-full capitalize bg-white border border-gray-300 rounded-lg p-[11px] text-[15px] outline-none"
                  name="source_type"
                  onChange={handleInputChange}
                  value={formData.source_type}
                >
                  <option value={""}>--select Source--</option>
                  {sourceDropdown.map((option) => (
                    <option
                      key={option.id}
                      value={option.id}
                      className="capitalize"
                    >
                      {option.source_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="w-1/2">
              <p className="mb-[5px] text-[15px]">
                It is Your First Baby ? <span className="text-red-500">*</span>
              </p>

              <div className="flex space-x-4">
                <button
                  type="button"
                  className={`px-6 py-2 text-lg border rounded-md ${
                    selected1 === "Yes"
                      ? "bg-green-500 text-white border-green-500"
                      : "bg-white text-black border-gray-300 hover:bg-gray-100"
                  }`}
                  onClick={() => handleSelect1("Yes")}
                >
                  Yes
                </button>
                <button
                  type="button"
                  className={`px-6 py-2 text-lg border rounded-md ${
                    selected1 === "No"
                      ? "bg-red-500 text-white border-red-500"
                      : "bg-white text-black border-gray-300 hover:bg-gray-100"
                  }`}
                  onClick={() => handleSelect1("No")}
                >
                  No
                </button>
              </div>
            </div>
          </div>

          <div>
            <p className="mt-5 text-black text-[14px] font-500">
              <label>Remarks</label>
            </p>
            <textarea
              className="mt-5 border-2 w-full border-black-500 p-1"
              id="remarks"
              name="remarks"
              rows="4"
              cols="50"
              onChange={handleInputChange}
              value={formData.remarks}
            ></textarea>
          </div>
          <div className="flex gap-[10px]">
            <button
              type="submit"
              className="bg-primary text-white px-[10px] py-[5px] rounded-[8px] mt-4 cursor-pointer "
            >
              SAVE
            </button>
            <div
              onClick={onClose}
              className="bg-primary text-white px-[10px] py-[5px] rounded-[8px] mt-4 cursor-pointer "
            >
              CLOSE
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Addincomingform;
